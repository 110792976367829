<template>
  <div>
    <div class="compont_p">
      添加退货
      <div class="btn flex_c_c btn1" @click="back">返回上级</div>
    </div>
    <div class="form_wrap">
      <el-form :model="formInline" label-width="110px" :rules="rules" ref="form">
        <el-form-item label="退货订单编号：">
          <el-input v-model="orderno" disabled placeholder="退货订单编号" style="width: 220px"></el-input>
        </el-form-item>
        <el-form-item label="勾选退货商品：">
          <el-table :data="returnData" @selection-change="selectionChange">
            <el-table-column type="selection" width="55" :selectable="selectable"> </el-table-column>
            <el-table-column prop="title" label="商品名称"> </el-table-column>
            <el-table-column prop="manufacturer" label="生产企业"> </el-table-column>
            <el-table-column prop="specification" label="商品规格"> </el-table-column>
            <el-table-column prop="price" label="价格"> </el-table-column>
            <el-table-column prop="number" label="购买数量" align="center"> </el-table-column>
            <el-table-column prop="outstock_number" label="出库数量" align="center"> </el-table-column>
            <el-table-column label="退货数量" width="200">
              <template slot-scope="{ row }">
                <el-input-number v-model="row.number1" :min="row.outstock_number == 0 ? 0 : 1" :max="Number(row.outstock_number)"></el-input-number>
              </template>
            </el-table-column>
          </el-table>
        </el-form-item>
        <el-form-item label="退货原因：" prop="reason_arr">
          <el-select v-model="reasonArr" multiple placeholder="请选择" @change="changeReason">
            <el-option v-for="item in options" :key="item.id" :label="item.title" :value="item.id"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传凭证：" prop="image">
          <upload :limit="4" @successUrl="successUrl" @deleteImage="deleteImage"></upload>
        </el-form-item>
        <el-form-item label="退货原因描述：">
          <el-input type="textarea" style="width: 520px" :rows="5" v-model="formInline.reason"></el-input>
        </el-form-item>
        <el-form-item label="">
          <div class="sub_btn flex_c_c" @click="onSubmit">提交退货</div>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import upload from "@/components/upload.vue";
export default {
  components: {
    upload,
  },
  data() {
    return {
      returnData: [],
      reasonArr: [],
      options: [],
      optionsObj: {},
      orderno: "",
      id: "",
      formInline: {
        id: "",
        reason: "",
        image: [],
        goods: [],
        reason_arr: [],
      },
      rules: {
        reason_arr: [{ required: true, message: "请选择退货原因", trigger: "change" }],
        image: [{ required: true, message: "请上传凭证", trigger: "change" }],
      },
    };
  },
  created() {
    this.orderno = this.$route.query.orderno;
    this.id = this.$route.query.id;
    this.getReturnAddress();
    this.getReturn();
    this.$api("account.getReturnReason").then((res) => {
      this.options = res.data;
      this.optionsObj = {}; //清空

      this.options.map((item) => {
        this.optionsObj[item.value] = { ...item };
      });
    });
    if (this.$route.query.orderno) {
      this.formInline.id = this.$route.query.id;
      this.formInline.orderno = this.$route.query.orderno;
      this.getDetail();
    }
  },
  methods: {
    back() {
      this.$router.go(-1);
    },
    getDetail() {
      this.$api("account.getOrderGoods", { orderno: this.$route.query.orderno, id: this.$route.query.id }).then((res) => {
        this.returnData = res.data.map((v) => {
          this.$set(v, "number1", 0);
          return v;
        });
      });
    },
    getReturn() {
      this.$api("account.getReturn", {
        ...this.form,
        page: this.page,
        page_size: this.page_size,
      }).then((res) => {
        this.tableData = res.data;
        this.total = res.listTotal;
      });
    },
    getReturnAddress() {
      this.$api("account.returnAddress", {}).then((res) => {
        this.returnAddressInfo = res.data;
      });
    },
    selectable(row) {
      if (row.ck_number == 0) {
        return false;
      } else {
        return true;
      }
    },
    selectionChange(e) {
      console.log("选择商品---", e);
      this.selectionList = e;
    },
    changeReason(e) {
      // console.log( e);
      // console.log("选择原因---", e);
      const selectedItems = this.options.filter((option) => e.includes(option.id));
      // 打印出所有字段信息
      console.log("选择原因---", selectedItems);
      this.formInline.reason_arr = selectedItems;
    },
    successUrl(data) {
      // this.formInline.images.push({ id: data.id });
      this.formInline.image.push(data);
    },
    deleteImage(file) {
      let index = 0;
      this.formInline.image.forEach((e, i) => {
        if (e.id == file.id) index = i;
      });
      this.formInline.image.splice(index, 1);
    },
    onSubmit() {
      // console.log("提交信息---", this.formInline);
      if (!this.selectionList.length) {
        this.$message.error("请选择退货商品");
        return;
      }
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.formInline.goods = [];
          this.selectionList.forEach((e) => {
            this.formInline.goods.push({ id: e.id, number: e.number1 });
          });
          this.$api("account.applyReturn", { ...this.formInline, id: this.$route.query.id }).then((res) => {
            if (res.code == 200) {
              this.$message.success("申请退货成功");
              this.$router.push({
                path: "/index/subRouter/returnGoods",
              });
            }
          });
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 120px;
    top: 0;
    width: 76px;
    height: 30px;
    line-height: 30px;
    background: #ff4c4c;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
  }
  .btn1 {
    background: @themeColor;
  }
}

.form_wrap {
  margin-top: 20px;
}
.sub_btn {
  margin-left: 100px;
  width: 76px;
  height: 30px;
  background: #ff4c4c;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  margin-left: 10px;
}
</style>
